/* Popup.css */
.popup-overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 1000;
}

.popup {
 background-color: white;
 padding: 20px;
 border-radius: 10px;
 width: 90%;
 max-width: 400px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
 text-align: center;
}

.popup-title {
 font-size: 1.5rem;
 color: #d9534f;
 margin-bottom: 10px;
}

.popup-message {
 font-size: 1rem;
 color: #333;
 margin-bottom: 20px;
}

.popup-close-btn {
 padding: 10px 20px;
 background-color: #004aad;
 color: white;
 border: none;
 border-radius: 5px;
 cursor: pointer;
 font-size: 1rem;
 transition: background-color 0.3s;
}

.popup-close-btn:hover {
 background-color: #002f6c;
}