/* IndependenceDay.css */
body {
 margin: 0;
 padding: 0;
 font-family: Arial, sans-serif;
 height: 100vh;
}

h1 {
 font-weight: 1000;
 font-size: 75px;
 text-align: center;
 margin: 20px 0;
 color: rgb(248, 124, 0);
 text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.493);
}


p {
 font-size: 26px;
 text-align: center;
 margin: 20px 0;
 color: aliceblue;
 text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.493);
}

video {
 top: 40px;
 width: 100%;
 height: 100%;
 object-fit: fill;
 position: fixed;
 z-index: -1;
 background: rgba(0, 0, 0, 0.895);

}